import "./nftlist.scss"
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {AuthContext, serverAddress} from "../../App";
import {responseCode} from "../../config";
import MenuIcon from "../../components/menuIcon/menuIcon";
import backgroundImage from "../../assets/background-image.png";
import logo from "../../assets/milk-logo.png";

export default function NFTlist(props){
    const [list, setList] = useState([]);
    const {state} = useContext(AuthContext);
    let navigate = useNavigate();
    const goToClaim = () => {
        navigate('/claim')
    }

    const goToDetail = (e) => {
        navigate('/detail/'+e.target.id)
    }
    
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    useEffect(() => {
        if(state.accessToken && state.accessToken.length > 0){
            try {
                fetch(
                    serverAddress + "/myNFTs?site=yili&pageSize=100&pageNum=1",
                    {
                        method: "GET",
                        headers: {"accessToken": state.accessToken},
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === responseCode.success ){
                            const nfts = result.data.nfts;
                            setList(nfts);
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }
    },[state.accessToken])

    return(
        <div className="list-page">
            <div className="ornament2"></div>
            <div className="ornament3"></div>
            <MenuIcon/>
            <div className='background'></div>
            <img className='logo' mode="widthFix" src={logo} alt=''/>
            <div className='content-container'>
                {list.map((nft, index)=>{
                    return (
                        <div className='detail-container' id={nft.id} key={index} onClick={goToDetail}>
                            <div className="detail-nft-container" id={nft.id}>
                                <img className="detail-nft-image" id={nft.id} src={nft.thumbnailURL} alt=''/>
                            </div>
                            <div className="detail-nft-name" id={nft.id}>母乳研究20年数字藏品</div>
                        </div>
                    )
                })}
                <div className="detail-container" onClick={goToClaim}>
                    <div className="detail-plus-container">
                        <div className="plus-sign"></div>
                    </div>
                    <div className="detail-nft-name">添加数字藏品</div>
                </div>
            </div>
        </div>
    )
}