import "./claim.scss"
import React, {useContext, useState, useEffect} from "react";
import {AuthContext, serverAddress} from "../../App";
import {responseCode} from "../../config";
import {useNavigate} from "react-router";
import {bottomToast} from "../../utils/toast";
import ProgressLoading from '../../components/progressLoading/ProgressLoadingConfig';
import MenuIcon from "../../components/menuIcon/menuIcon";
import backgroundImage from "../../assets/background-image.png";
import logo from "../../assets/milk-logo.png";
import questionMark from '../../assets/question-mark.png'

export default function Claim(){
    const [code, setCode] = useState();
    const [loading, setLoading] = useState(false);
    const {state} = useContext(AuthContext);
    const [nftData, setNftData] = useState();
    const navigate = useNavigate();

    const closeLoading = () => {
        const {name,tokenId,thumbnailURL,collectionInfo,displayURL} = nftData;
        navigate("/congrats?name=" + name + "&tokenId=" + tokenId + "&thumbnailurl=" + thumbnailURL + "&displayurl=" + displayURL +"&desc=" + collectionInfo.description+"&total="+ collectionInfo.amount);
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    
    const handleClaim = () => {
        if(!state.isLogin){
            navigate("/login");
        }else{
            try {
                fetch(
                    serverAddress + "/nfts/redemption",
                    {
                        method: "POST",
                        headers: {"accessToken": state.accessToken},
                        body: JSON.stringify({"code": code })
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === responseCode.success ){
                            setLoading(true);
                            const nft = result.data.nfts;
                            setNftData(nft[0])
                        }else if(result.returnCode === responseCode.redemptionCodeInvalid){
                            bottomToast("请输入正确的领取码");
                        }else if(result.returnCode === responseCode.redemptionCodeAlreadyUsed){
                            bottomToast("该兑换码已使用");
                        }else if(result.returnCode === "24000"){
                            bottomToast("请输入正确的领取码");
                        } else if (result.msg === 'Request too much') {
                            bottomToast('请在30s后重试');
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }

    }
    return(
        <div className="claim-page">
            <MenuIcon/>
            <div className='background'></div>
            <img className='logo' mode="widthFix" src={logo} alt=''/>
            <div className="ornament3"></div>
            {loading?<ProgressLoading text={'努力铸造中...'} closeLoading={closeLoading}/>:null}
            <div className="claim-page-content">
                <div className="card-container">
                    <img className="question-mark" src={questionMark} alt=""/>
                </div>
                <div className='input-container'>
                    <div className="inside-input-border">
                        <input className="input-code" size="1"
                            onChange={(e) => {setCode(e.target.value)}}
                            onClick={(e) => {e.target.scrollIntoView({inline: "start"})}}
                            placeholder="输入领取码"/>
                    </div>
                </div>
                <button className='claim-button' onClick={handleClaim}>
                    <div className='inside-button'>立即领取</div>
                </button>
            </div>
        </div>
    )
}