import './ProgressLoading.scss'
import {useEffect, useState} from "react";
import loading from '../../assets/loading.gif'
import backgroundImage from "../../assets/background-image.png";
import longImage from "../../assets/poster-page-background.png";
import logo from "../../assets/milk-logo.png";
import loadingContainer from "../../assets/loading-container.png";

export default function ProgressLoading(props) {
  const {text = ''} = props

  const [percent, setPercent] = useState(0)

  useEffect(() => {
    if (percent < 105) {
      const counter =  setTimeout(() => {
        setPercent(percent+3.9)
      }, 100);
      return ()=>{
        clearTimeout(counter);
      }
    } else {
      props.closeLoading()
    }
  }, [percent]);

  useEffect(() =>{
    setPercent(1)
  }, [])

  return (
    <div className="progress-loading-page">
      <div className="loading-ornament2"></div>
      <div className="loading-ornament3"></div>
      <div className="loading-ornament4"></div>
      <div className={`loading-background`}></div>
      <div className="loading-page-content">
        <img className={`loading-logo`} mode="widthFix" src={logo} alt=''/>
        <div className='loading-bar'>
          <img className="loading-container" src={loadingContainer} alt="" />
          <img className="progress-bar" src={loading} alt="" />
        </div>
        <div className='loading-text'>{text}</div>
      </div>
    </div>
  )
}