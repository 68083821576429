import "./detail.scss";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { serverAddress } from "../../App";
import { responseCode } from "../../config";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/detail-page-background.png";
import logo from "../../assets/milk-logo.png";
import light from '../../assets/milk-light.png'
import detailPageLogo from "../../assets/detail-page-milk-logo.png";
import detailPageSignature from "../../assets/detail-page-signature.png";
import shareButton from "../../assets/detail-page-share-button.png";

export default function Detail(props) {
    const navigate = useNavigate();
    const { id } = useParams();

    const [nftInfo, setInfo] = useState({});

    const getSubStr = (str) => {
        if(str&&str.length > 25){
            let subStr1 = str.substr(0, 7);
            let subStr2 = str.substr(str.length - 19, 19);
            return subStr1 + "..." + subStr2;
        }
       return str;
    };

    const goToPoster = () => {
        navigate("/poster/" + id);
    }

    const navBack = () => {
        navigate(-1)
    };

    const getExtension = (name) => {
        if (name) {
            return name.substring(name.lastIndexOf("."))
        }
    }

    useEffect(() => {
        try {
            fetch(serverAddress + "/nft/" + id, {
                method: "GET",
            }).then((r) => {
                r.json().then((result) => {
                    if (result.returnCode === responseCode.success) {
                        const { ownedAt, name, ownerUserInfo, displayURL, collectionInfo,creator, tokenId, thumbnailURL  } = result.data;
                        setInfo({ ownedAt, id, name, ownerUserInfo, image:displayURL, publisher: collectionInfo.publisher, address: collectionInfo.contractAddress, creator, tokenId, total:collectionInfo.amount, thumbnailURL });
                    }
                });
            });
        } catch (error) {
            console.log("error", error);
        }
    }, [id]);

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    useEffect(() => {
        try {
            fetch(serverAddress + "/nft/" + id, {
                method: "GET",
            }).then((r) => {
                r.json().then((result) => {
                    if (result.returnCode === responseCode.success) {
                        const { ownedAt, name, ownerUserInfo, displayURL, collectionInfo,creator, tokenId, thumbnailURL } = result.data;
                        setInfo({ ownedAt, id, name, ownerUserInfo, image:displayURL, publisher: collectionInfo.publisher, address: collectionInfo.contractAddress, creator, tokenId, total:collectionInfo.amount, thumbnailURL });
                    }
                });
            });
        } catch (error) {
            console.log("error", error);
        }
    }, []);

    return (
        <div className="detail-page">
            <div className="ornament3"></div>
            <div className='background'></div>
            <button className="back-icon" onClick={navBack}></button>
            <img className='logo' mode="widthFix" src={logo} alt=''/>
            <div className="detail-page-content">
                <div className="nft-title"></div>
                <div className="nft-id">#{nftInfo.tokenId}/{nftInfo.total}</div>
                <img className='background-light' src={light} alt=''/>
                <div className="nft-container">
                    {(() => {
                      const ext = getExtension(nftInfo.image)
                      console.log(ext)
                      switch (ext) {
                        case ".png": case ".jpg": case ".jpeg":
                          return <img className="nft-image" src={nftInfo.image} alt=""/>
                        case ".mp4": case ".m4v":
                          return (<video className="nft-image" poster={nftInfo.thumbnailURL} autoPlay controls>
                                    <source src={nftInfo.image} />
                                  </video>)
                        default:
                          break;
                      }
                    })()}
                </div>
                <div className='logo-signature-container'>
                    <img className="detail-page-logo" src={detailPageLogo} alt=''/>
                    <img className="detail-page-signature" src={detailPageSignature} alt=''/>
                </div>
                <div className="detail-page-info-container">
                    <div className="info-container-title">数字藏品信息</div>
                    <div className="info-content-container">
                        <div className="info-title">发行方</div>
                        <div className="info-content">{getSubStr(nftInfo.publisher)}</div>
                    </div>
                    <div className="info-content-container">
                        <div className="info-title">创作时间</div>
                        <div className="info-content">{nftInfo.ownedAt?nftInfo.ownedAt.toString().substr(0,10):''}</div>
                    </div>
                    <div className="info-content-container">
                        <div className="info-title">创作作者</div>
                        <div className="info-content">{getSubStr(nftInfo.creator)}</div>
                    </div>
                    <div className="info-content-container">
                        <div className="info-title">区块链唯一编号</div>
                        <div className="info-content">{getSubStr(nftInfo.address)}</div>
                    </div>
                </div>
                <img onClick={goToPoster} className="detail-page-share-button" src={shareButton} alt=""/>
            </div>
        </div>
    );
}
