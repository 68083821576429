import './home.scss'
import MenuIcon from "../../components/menuIcon/menuIcon";
import Description from "../../components/description/description"
import {useContext, useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext, serverAddress} from "../../App";
import {responseCode} from "../../config";
import logo from "../../assets/milk-logo.png";

export default function Home() {
  const { state } = useContext(AuthContext);
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  const claim = () => {
    if(state.isLogin){
      navigate('/claim')
    }else{
      navigate('/login');
    }
  }

  const goToList = () => {
    navigate('/list');
  }

  useEffect(() => {
    if(state.accessToken && state.accessToken.length > 0){
        try {
            fetch(
                serverAddress + "/myNFTs?site=yili&pageSize=5&pageNum=1",
                {
                    method: "GET",
                    headers: {"accessToken": state.accessToken},
                }
            ).then((r) => {
                r.json().then((result) => {
                    if(result.returnCode === responseCode.success ){
                        const nfts = result.data.nfts;
                        setList(nfts);
                    }
                })
            } );
        } catch (error) {
            console.log("error", error);
        }
    }
  },[state.accessToken])

  return (
    <div className='home-page'>
      <MenuIcon/>
      <div className='background'></div>
      <img className='logo' mode="widthFix" src={logo} alt=''/>
      <div className="ornament1"></div>
      <div className="ornament3 animate1"></div>
      <div className='home-page-content'>
        <div className="nft-title"></div>
        <div className="nft-id">#01/20</div>
        <div className="nft-container">
          <div className="home-page-nft-image"></div>
        </div>
        <Description desc={'伊利金领冠母乳研究20年特别发行纪念版数字藏品'}/>
        {list.length>0&&state.isLogin?
          <button onClick={goToList} className='retrieve-button retrieve-button2'>
            <div className='inside-button'>查看我的藏品</div>
          </button>
          :
          <button onClick={claim} className='retrieve-button'>
            <div className='inside-button'>立即领取</div>
          </button>
        }
        {list.length>0&&state.isLogin?<div className='small-button' onClick={claim}>领取藏品</div>:null}
      </div>
      <div className='bottom-text2'>*中国发明专利 专利号：ZL201680013841.3 发明名称：核苷酸组合物及其在食品中的应用</div>
      <div className='bottom-text'>[1]指伊利集团已开展中国母乳研究工作20年，不代表本品可以替代母乳</div>
    </div>
  )
}