import './description.scss';
import leftQuotation from '../../assets/left-quotation.png';
import rightQuotation from '../../assets/right-quotation.png';

export default function Description(props) {
  const {desc} =props;

  return(
       desc && desc.length> 0? <div className="desc-container">
          <div>
              <img src={leftQuotation} alt='' style={{ width: '12px', height: '9px', paddingRight: '5px', paddingBottom: '33px' }}/>
          </div>
          <div className="desc-content">
              {desc}
          </div>
          <img src={rightQuotation} alt='' style={{ width: '12px', height: '9px', paddingLeft: '5px', paddingTop: '33px'  }}/>
      </div>: null
  )
}