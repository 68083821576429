import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {AuthContext, serverAddress} from "../../App";
import "./poster.scss";
import * as htmlToImage from "html-to-image";
import ProgressLoading from '../../components/progressLoading2/ProgressLoadingConfig';
import backgroundImage from "../../assets/poster-page-background.png";
import Download from './assets/download.svg';
import {bottomToast} from "../../utils/toast";
import light from '../../assets/milk-light.png'
import detailPageLogo from "../../assets/detail-page-milk-logo.png";
import detailPageSignature from "../../assets/detail-page-signature.png";
import qrcodeGroup from '../../assets/qrcode-group.png'

export default function Poster(){

    const { state } = useContext(AuthContext);
    const stageRef = useRef();

    const navigate = useNavigate();
    const [nft, setNft] = useState();
    const [loaded, setLoaded] = useState(false);
    const [nftLoaded, setNftLoaded] = useState(false);
    const [showLoading, setShowLoading] = useState(true);

    const [tokenId, setTokenId] = useState();
    const [owner, setOwner] = useState();
    const [name, setName] = useState();
    const [publisher, setPublisher] = useState();
    const [creator, setCreator] = useState();
    const [address, setAddress] = useState();
    const [total, setTotal] = useState();
    const params = useParams();

    const getSubStr = (str) => {
        if(str&&str.length > 25){
            let subStr1 = str.substr(0, 7);
            let subStr2 = str.substr(str.length - 19, 19);
            return subStr1 + "..." + subStr2;
        }
       return str;
    };

    const displayPoster = (url) => {
        const parentDiv = document.getElementById('stageDiv');
        parentDiv.innerHTML ='';
        const result = document.createElement("img");
        result.style.minHeight = '100vh';
        result.style.width = '100vw';
        result.style.position = 'absolute';
        result.style.left = '0';
        result.src = url;
        stageRef.current.appendChild(result);
        stageRef.current.scrollTo(0,0);
        result.onload = () => {
            setLoaded(true);
        };
    }

    const setInformation=(res)=>{
        setTokenId(res.data.tokenId);
        setOwner(res.data.ownedAt);
        setName(res.data.name);
        setPublisher(res.data.collectionInfo.publisher);
        setCreator(res.data.creator)
        setAddress(res.data.collectionInfo.contractAddress);
        setTotal(res.data.collectionInfo.amount)
    }

    const closeLoading = () => {
        setShowLoading(false);
    }

    const navBack = () => {
        navigate(-1)
    };

    const errorToast =() => {
        bottomToast('网络波动，请重试', 'center',()=>{
            navigate(`/detail/${params.id}`)
        })
    }

    useEffect(() => {
            if (state.isLogin) {
                fetch(serverAddress + "/nft/"+ params.id, { method: "GET" })
                    .then((res) => {
                        if (res.status === 200) {
                            res.json().then((res) => {
                                if (res.returnCode === "20000") {
                                    let url = res.data.thumbnailURL;
                                    let nftImg = document.createElement("img");
                                    nftImg.crossOrigin = "anonymous";
                                    nftImg.src = url;
                                    console.log(nftImg,'ssss')
                                    nftImg.onload = function(){
                                        setNftLoaded(true);
                                        setNft(nftImg.src);
                                        console.log('zzzzgg',url)
                                    }
                                    setInformation(res)
                                }
                            }).catch(()=>errorToast());
                        }
                    });
            }
        }, [state.isLogin]);

    useEffect(() => {
        if (nftLoaded) {
            setTimeout(() => {
                fetch(serverAddress + "/poster/isUploaded?nftId="+ params.id, {
                    method:"GET",
                    headers: {"accessToken": state.accessToken}}).then((res) => {
                    if(res.status === 200){
                        res.json().then( r => {
                            if(r.returnCode === "20000"){
                                if(r.data.length === 0){
                                    htmlToImage.toBlob(stageRef.current).then(() => {
                                        htmlToImage.toBlob(stageRef.current).then(() => {
                                            htmlToImage.toBlob(stageRef.current).then((blob) => {
                                                let img = new File([blob],'poster.png');
                                                let formData = new FormData();
                                                formData.append('file', img);
                                                fetch(serverAddress + "/poster/uploadOss?nftId=" + params.id,{
                                                    method:"POST",
                                                    headers: {"accessToken": state.accessToken},
                                                    body: formData
                                                }).then((res) => {
                                                    if(res.status === 200){
                                                        res.json().then(r => {
                                                            if(r.returnCode === "20000"){
                                                                displayPoster(r.data);
                                                            }
                                                        })
                                                    }
                                                }).catch(()=>errorToast())
                                            });
                                        })
                                    })
                                }else{
                                    displayPoster(r.data);
                                }
                            }
                        })
                    }
                }).catch(()=> errorToast())
            },500);
        }
    }, [nftLoaded]);

    useEffect(() => {
        if(!loaded){
                document.body.style.overflow='hidden';
        }else{
                 document.body.style.overflow='';
        }
    },[loaded])

    useEffect(() => {
        if(!showLoading){
                document.body.style.overflow='hidden';
        }else{
                 document.body.style.overflow='';
        }
    },[showLoading])

    return(
        <div className="poster-page ">
            <ProgressLoading isShowing={!loaded} text={'数字藏品分享页面生成中...'} type={'2'} />
            <button className="back-icon" onClick={navBack}></button>
            <div style={{padding: '23px 17px 0 0',position: 'absolute',right:'0', top: '0',zIndex:6}} onClick={()=>bottomToast('长按图片下载海报')}>
                <img src={Download} alt=''/>
            </div>

            <div ref={stageRef} id='stageDiv' className='stageDiv'>
                <div className='background'></div>
                <div className="ornament3"></div>
                <div className='poster-page-content'>
                    <div className="nft-title"></div>
                    <div className="nft-id">#{tokenId}/{total}</div>
                    <img className='background-light' src={light} alt=''/>
                    <div className="nft-container">
                        <img className="nft-image" src={nft} alt=""/>
                    </div>
                    <div className='logo-signature-container'>
                        <img className="detail-page-logo" src={detailPageLogo} alt=''/>
                        <img className="detail-page-signature" src={detailPageSignature} alt=''/>
                    </div>
                    <div className="poster-page-info-container">
                        <div className="info-container-title">数字藏品信息</div>
                        <div className="info-content-container">
                            <div className="info-title">发行方</div>
                            <div className="info-content">{getSubStr(publisher)}</div>
                        </div>
                        <div className="info-content-container">
                            <div className="info-title">创作时间</div>
                            <div className="info-content">{owner?owner.toString().substr(0,10):''}</div>
                        </div>
                        <div className="info-content-container">
                            <div className="info-title">创作作者</div>
                            <div className="info-content">{getSubStr(creator)}</div>
                        </div>
                        <div className="info-content-container">
                            <div className="info-title">区块链唯一编号</div>
                            <div className="info-content">{getSubStr(address)}</div>
                        </div>
                    </div>
                    <img className="qrcode-group" src={qrcodeGroup} alt=""/>
                </div>
            </div>
        </div>
    );
}
