import {useNavigate} from "react-router";
import "./congrats.scss";
import React, {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import Description from "../../components/description/description"
import backgroundImage from "../../assets/background-image.png";
import logo from "../../assets/milk-logo.png";
import milkNftImage from '../../assets/milk-nft-image.png'
import MenuIcon from "../../components/menuIcon/menuIcon";
import light from '../../assets/milk-light.png'

export default function Congrats(props){
    let navigate = useNavigate();
    let [params] = useSearchParams();

    const confirm = () => {
        navigate('/list');
    }

    const getExtension = (name) => {
        if (name) {
            return name.substring(name.lastIndexOf("."))
        }
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    const displayURL = params.get("displayurl");
    const thumbnailURL = params.get("thumbnailurl")
    const name = params.get("name");
    const desc = params.get("desc");
    const tokenId = params.get("tokenId")
    const total = params.get("total")

    return(
        <div className='congrats-page'>
            <MenuIcon/>
            <div className='background'></div>
            <img className='logo' mode="widthFix" src={logo} alt=''/>
            <div className="ornament3"></div>
            <div className="congrats-page-content">
                <div className="nft-title"></div>
                <div className="nft-id">#{tokenId}/{total}</div>
                <img className='background-light' src={light} alt=''/>
                <div className="nft-container">
                    {(() => {
                      const ext = getExtension(displayURL)
                      console.log(ext)
                      switch (ext) {
                        case ".png": case ".jpg": case ".jpeg":
                          return <img className="nft-image" src={displayURL} alt=""/>
                        case ".mp4": case ".m4v":
                          return (<video className="nft-image" poster={thumbnailURL} autoPlay controls>
                                    <source src={displayURL} />
                                  </video>)
                        default:
                          break;
                      }
                    })()}
                </div>
                <Description desc={'伊利金领冠母乳研究20年特别发行纪念版数字藏品'}/>
                <button onClick={confirm} className='retrieve-button'>
                    <div className='inside-button'>确定</div>
                </button>
            </div>
        </div>
    )
}