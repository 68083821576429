import './App.scss';
import { Routes, Route, BrowserRouter} from "react-router-dom";
import React, {useState} from "react";
import Poster from "./pages/posterPage/Poster";
import Claim from "./pages/claimPage/Claim";
import NftList from "./pages/nftListPage/NFTlist";
import {createContext, useEffect, useReducer} from "react";
import {responseCode, mainTheme, colorTheme} from "./config";
import About from "./pages/aboutPage/About";
import Congrats from "./pages/congratsPage/Congrats";
import Login from "./pages/loginPage/Login";
import Home from "./pages/homePage/Home";
import Detail from './pages/detailPage/Detail';

const initialState = {
    isLogin: null,
    userId: null,
    accessToken: null,
}

window.addEventListener('storage', (e) => {
    localStorage.setItem(e.key, e.oldValue);
});

const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            localStorage.setItem("userId", action.payload.userId);
            localStorage.setItem("accessToken", action.payload.accessToken);
            return {
                ...state,
                isLogin: true,
                userId: action.payload.userId,
                accessToken: action.payload.accessToken,
            };
        case "LOGOUT":
            localStorage.removeItem("userId");
            localStorage.removeItem("accessToken");
            return {
                ...state,
                isLogin: false,
                userId: null,
                accessToken: null,
            };
    }
}

export const portalAddress = process.env.REACT_APP_PORTAL_ADDRESS;
export const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;

export const appId = "wilding";
export const AuthContext = createContext();
export const ThemeCtx = createContext({});

function App() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [theme, setTheme] = useState({
        main: mainTheme,
        color: colorTheme
    });

    useEffect(async() => {
        let userId = localStorage.getItem("userId");
        let accessToken = localStorage.getItem("accessToken")
        if (userId && accessToken) {
            try {
                const response = await fetch( portalAddress + "/user/token", {
                    method: "GET",
                    headers: { accessToken },
                } );
                const json = await response.json();
                if (json.returnCode === responseCode.success) {
                    dispatch({
                        type: "LOGIN",
                        payload: {
                            isLogin: true,
                            userId,
                            accessToken
                        }
                    })
                }else{
                    dispatch({
                        type: "LOGOUT",
                    })
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            dispatch({
                type: "LOGOUT",
            })
        }
    },[])
  return (
    <div className="App" id='app'>
      <AuthContext.Provider value = {{state, dispatch}}>
        <ThemeCtx.Provider value={{ theme, setTheme }}>
          <BrowserRouter>
            <Routes>
            <Route path = "/" element = {<Home />}/>
            <Route path = "/about" element = {<About />}/>
            <Route path = "/claim" element = {<Claim />} />
            <Route path = "/list" element = {<NftList />} />
            <Route path = "/congrats" element = {<Congrats />} />
            <Route path = "/detail/:id" element = {<Detail />} />
            <Route path = "/poster/:id" element = {<Poster />} />
            <Route path = "/login" element = {<Login />} />
            </Routes>
          </BrowserRouter>
        </ThemeCtx.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
